import React, { useState, useEffect } from "react";
import Footer from "../../comman/Footer";
import { GetPackageById } from "../../Service/api.sevice";
import { PackageId } from "../../utils/helper";
import Header from "../../comman/Header";
import group_tour from "../../assets/image/group_tour.jpg";
import HomeHeroBanner from "../../component/HomeHeroBanner";
import PackageCard from "../../component/PackageCard";
import "./GroupTour.css";
function GroupTour() {
  const [packageData, setPackageData] = useState(null);

  useEffect(() => {
    GetDomesticData();
  }, []);

  const GetDomesticData = async () => {
    const getId = PackageId.GroupTour;
    const responseData = await GetPackageById(getId, 0);
    setPackageData(responseData?.data);
  };
  return (
    <>
      <Header />
      <HomeHeroBanner Text={``} Image={group_tour} />
      <section className="center_container">
        <div className="card_container">
          {packageData &&
            packageData.length > 0 &&
            packageData.map((data, index) => {
              return (
                <PackageCard data={data} key={`packageData_group${index}`} />
              );
            })}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default GroupTour;
