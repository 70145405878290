import React from "react";
import "./Header.css";
import Logo from "../../assets/image/logo.png";
import { Link, NavLink } from "react-router-dom";
import { scrollTop } from "../../utils/helper";
function Header() {
  return (
    <>
      <header className="stickynav">
        <nav>
          <div className="Upper_Nav">
            <p>shreeumiyatours2013@gmail.com</p>
            <p>+91 9773211424</p>
          </div>
          <div className="Navbar">
            <div className="logo">
              <Link to="/" onClick={scrollTop}>
                <img className="logo-img" src={Logo} alt="" />
              </Link>
            </div>
            <input type="checkbox" id="click" />
            <label htmlFor="click" className="menu-btn">
              <i className="fas fa-bars"></i>
            </label>
            <ul>
              <li>
                <NavLink to="/" onClick={scrollTop}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/domesticpackage"
                  onClick={scrollTop}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Domestic Package
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={scrollTop}
                  to="/internationalpackage"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  International Package
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={scrollTop}
                  to="/grouptour"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Group Tour
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hotelbooking"
                  onClick={scrollTop}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Hotel Booking
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/rental"
                  onClick={scrollTop}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Car/Bus Rental
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/aboutus"
                  onClick={scrollTop}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contactus"
                  onClick={scrollTop}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
