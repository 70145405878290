import NavRight from "../../assets/image/nav_right._white.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CloseButton from "../../assets/image/close.svg";
import "./ModalSlider.css";
import { Link } from "react-router-dom";
import { scrollTop } from "../../utils/helper";
function ModalSlider(props) {
  const [open, setOpen] = useState();

  useEffect(() => {
    if (props.isOpen) {
      setOpen(true);
    }
  }, [props.isOpen]);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  return (
    <>
      <div>
        <Modal show={props.isOpen} size="lg" centered>
          <Modal.Header>
            <div
              onClick={() => handleClose()}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Modal.Title id="contained-modal-title-vcenter">
                {props.Tittle}
              </Modal.Title>
              <img src={CloseButton} width="20px" height="20px" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              spaceBetween={0}
              updateOnWindowResize
              observer
              observeParents
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
              slidesPerView={1}
            >
              {props.Images?.map((DATA, index) => {
                return (
                  <SwiperSlide key={`Images${index}`}>
                    <img
                      src={DATA.imageUrl}
                      alt="SLide_Images"
                      style={{ objectFit: "contain" }}
                      width="100%"
                      height="200px"
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Modal.Body>
          <Modal.Footer>
            <Link
              to="/inquiry"
              onClick={scrollTop}
              state={{
                hotelId: props.Id ? props.Id : null,
                packageId: null,
                carId: props.carId ? props.carId : null,
              }}
            >
              <div className="modal_footer">
                <p> inquiry </p>
                <img src={NavRight} alt="" width="50px" height="20px" />
              </div>
            </Link>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default ModalSlider;
