import { Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./packageImageVideo.css";
function PackageImageVideo({ data }) {
  const [firstImage, setFirstImage] = useState();
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (data.PackageImageGallery) {
      setFirstImage(data?.PackageImageGallery[0]?.imageUrl);

      for (let i = 1; i < data.PackageImageGallery.length; i++) {
        images.push(data.PackageImageGallery[i]);
      }
    }
  }, [data]);

  return (
    <>
      <section className="center_container">
        <div className="heading_container_Package_details">
          {/* <div> */}
          <div className="heading_column heading_left_column">
            <div>
              <p className="heading_text_1">{data.tittle}</p>

              <div className="stars_box">
                <Rating
                  name="half-rating-read"
                  defaultValue={data.avgReview}
                  precision={0.5}
                  readOnly
                />
                <span>{`(${data.avgReview}Review)`}</span>
              </div>
            </div>
          </div>
          <div className="heading_column heading_righ_column_package_details">
            <div>
              <span className="package_price">₹{`${data.price}`} </span>{" "}
              <span id="package_person">/ per person</span>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>

      <section>
        <div className="vi_img_box center_container">
          <div className="vi_img_box_column video_box">
            <iframe
              src={data.videolink}
              height="100px"
              width="100px"
              title="videolink"
            ></iframe>
          </div>
          <div className="vi_img_box_column image_box">
            {firstImage && (
              <div className="image_box_1">
                <img src={firstImage} alt="" />
              </div>
            )}
            <div className="image_box_2">
              {images?.map((res, index) => {
                return (
                  <div key={`image_box_2${index}`}>
                    <img src={res?.imageUrl} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PackageImageVideo;
