import React from "react";
import { Link } from "react-router-dom";
import PackageDetail from "../Package/PackageDetail";
import Sidebar from "../Sidebar";
import HotelData from "./HotelData";

function AdminHotel() {
  return (
    <>
      <div className="admin_main_section">
        <Sidebar />
        <div className="admin_start">
          <div className="heading_div"></div>
          <div className="from_Top">
            <Link to="/createhotel">
              <button className="submit_Btn">create hotel</button>
            </Link>
          </div>
          <HotelData />
        </div>
      </div>
    </>
  );
}

export default AdminHotel;
