import React from "react";
import ModalSlider from "../ModalSlider";
import NavRight from "../../assets/image/nav_right._white.svg";
import "./CarRentalCard.css";
function CarRentalCard({ data }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const ModalCloseHandler = () => {
    setOpen(false);
  };

  return (
    <div className="car_section_column">
      <div className="car_section_img_text">
        <img src={data.imageUrl} alt="" />
        <div className="car_section_text">
          <p>{data.name}</p>
          <div className="Price_Box_Text">
            <h5>{`₹${data.price}`}</h5>
            <h5 style={{ color: "#fb9912" }}>/Day</h5>
          </div>
        </div>
      </div>
      <div className="car_inquiry_box" onClick={() => handleOpen()}>
        <span>View More</span>
        <img src={NavRight} alt="" width="50px" height="20px" />
      </div>
      <ModalSlider
        isOpen={open}
        handleClose={ModalCloseHandler}
        Tittle={data.name}
        Images={data.CarRentalImages}
        carId={data.id}
      />
    </div>
  );
}

export default CarRentalCard;
