import React from "react";
import "./PackageReview.css";

function PackageReview({ Image, Message, Name }) {
  return (
    <div className="review_container">
      <div className="review_img">
        <img src={Image} alt="" className="Review_Image_circle" />
      </div>
      <div className="review_content">
        <h4>{Name}</h4>
        <p>{Message}</p>
      </div>
    </div>
  );
}

export default PackageReview;
