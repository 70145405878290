import React, { useEffect, useState } from "react";
import AddIcon from "../../assets/image/Icon_Add.svg";
import DeleteIcon from "../../assets/image/delete.png";
import { AddIncExc } from "../../Service/admin.api.service";
import { errorToast } from "../../utils/helper";
function IncExc({ PackageId, IncExcinput, flag }) {
  const input = [
    {
      packageId: parseInt(PackageId),
      name: "",
      type: "",
    },
  ];
  const [selectedType, setSelectedType] = useState("");
  const [item, setItem] = useState("");
  const [totalIncExcitem, setTotalIncExc] = useState(1);
  const [Data, setData] = useState([]);
  const [errorText, setErrorText] = useState("");

  const [dataList, setDataList] = useState();

  useEffect(() => {
    if (flag) {
      setDataList(IncExcinput);
    } else {
      setDataList(input);
    }
  }, [flag]);

  const iconHandler = async () => {
    setDataList((s) => {
      return [
        ...s,
        {
          packageId: parseInt(PackageId),
          name: "",
          type: "",
        },
      ];
    });
  };

  const deleteHandler = async (index) => {
    setDataList((s) => {
      delete s[index];
      return [...s.filter(Boolean)];
    });
  };

  const submitHandler = async () => {
    let isEmptyInput = false;

    for (let i = 0; i < dataList.length; i++) {
      const element = dataList[i];
      if (element.name.trim() == "" || element.type.trim() == "") {
        isEmptyInput = true;
        break;
      }
    }
    if (isEmptyInput) {
      errorToast("please fill up data");
    } else {
      const response = await AddIncExc({ incExc: dataList });
    }
  };

  const setInputData = async (e, value, key, index) => {
    e.preventDefault();

    setDataList((s) => {
      const newArr = s.slice();
      newArr[index][key] = value;
      return newArr;
    });
  };

  return (
    <>
      <div className="incExc_main">
        <div>
          <h2> item </h2>
        </div>
        <div>
          <h2> Type </h2>
        </div>
        <div></div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="plus_icon_incexc">
          <img src={AddIcon} alt="PlusIcon" onClick={() => iconHandler()} />
        </div>
      </div>
      {dataList &&
        dataList.length > 0 &&
        dataList.map((d, index) => {
          return (
            <div className="incExc_Data" key={`ImageGallery${index}`}>
              <div>
                <input
                  type="text"
                  className="create_package_field"
                  placeholder="incexc"
                  onChange={(e) =>
                    setInputData(e, e.target.value, "name", index)
                  }
                  value={d.name}
                />
              </div>
              <div>
                <select
                  onChange={(e) =>
                    setInputData(e, e.target.value, "type", index)
                  }
                >
                  {d.type === "INCLUSION" ? (
                    <>
                      <option value={`SELECT`}>--select--</option>
                      <option value={`INCLUSION`} selected>
                        inclusion
                      </option>
                      <option value={`EXCLUSION`}>exclusion</option>
                    </>
                  ) : d.type === "EXCLUSION" ? (
                    <>
                      <option value={`SELECT`}>--select--</option>
                      <option value={`INCLUSION`}>inclusion</option>
                      <option value={`EXCLUSION`} selected>
                        exclusion
                      </option>
                    </>
                  ) : (
                    <>
                      <option value={`SELECT`} selected>
                        --select--
                      </option>
                      <option value={`INCLUSION`}>inclusion</option>
                      <option value={`EXCLUSION`}>exclusion</option>
                    </>
                  )}
                </select>
              </div>
              {errorText && <h3> {errorText} </h3>}
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    src={DeleteIcon}
                    alt="deleteIcon"
                    onClick={() => deleteHandler(index)}
                  />
                </div>
              </div>
            </div>
          );
        })}

      <button className="submit_Btn" onClick={submitHandler}>
        submit
      </button>
    </>
  );
}

export default IncExc;
