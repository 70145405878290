import { ENDPOINTURL } from "../utils/helper";
import { DELETE, get, post, put } from "../webRequest";
import { User, userReviewCount } from "../utils/Data";

// get categorey to display
export const GetCategorey = () => {
  return get(`${ENDPOINTURL}/category/${User.userId}?subcategory=false`);
};

// sent contact-us data Like name email and message to the api
export const ContactData = (data) => {
  return post(`${ENDPOINTURL}/contact`, data);
};

// get package by id
export const GetPackageById = (id, count) => {
  return get(`${ENDPOINTURL}/package/category/${id}?count=${count}`);
};

// get all package by type

export const GetAllPackage = (type, count) => {
  return get(`${ENDPOINTURL}/package/?type=${type}&count=${count}`);
};

// get all car data

export const GetAllCarData = () => {
  return get(`${ENDPOINTURL}/car/${User.userId}`);
};

//  get all hotel Data

export const GetAllHotelDetail = () => {
  return get(`${ENDPOINTURL}/hotel/${User.userId}`);
};

// get packageDetail by id

export const GetAllPackageDetails = (Packageid) => {
  return get(`${ENDPOINTURL}/package/${Packageid}`);
};

// create CustomerInquiry

export const CustomerInquiryData = (data) => {
  return post(`${ENDPOINTURL}/contact/inquiry`, data);
};

export const GetUserReview = () => {
  return get(
    `${ENDPOINTURL}/contact/review/${User.userId}?count=${userReviewCount}`
  );
};

export const UserReview = (data) => {
  return post(`${ENDPOINTURL}/contact/review`, data);
};
