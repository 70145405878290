import React from "react";
import "./Footer.css";
import Logo from "../../assets/image/logo.png";
import { Link } from "react-router-dom";
import { scrollTop } from "../../utils/helper";
function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer_container">
          <div className="l-footer">
            <div>
              <img className="" src={Logo} alt="" />
            </div>
            <p>
              We are a team of professionals driven by a desire to change the whole landscape of Travel and
              Tourism in the region,We offer unique range of
              Tourism products from General Tourism, Adventure Tourism, Film Tourism, Corporate
              Travel, and Incentive Tourism to Event Management
            </p>
          </div>
          <ul className="r-footer">
            <li>
              <p className="footer_header"> Quick Links</p>
              <ul className="box">
                <li>
                  <Link onClick={scrollTop} to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/domesticpackage" onClick={scrollTop}>
                    Domestic Package
                  </Link>
                </li>
                <li>
                  <Link to="/internationalpackage" onClick={scrollTop}>
                    International Package
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to="/grouptour">
                    Group Tour
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to="/hotelbooking">
                    Hotel Booking
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to="/rental">
                    Car/Bus Rental
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to="/aboutus">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to="/contactus">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </li>
            <li className="features">
              <p className="footer_header">Address</p>
              <ul className="box h-box">
                <li>
                  <a
                    href="mailto:shreeumiyatours2013@gmail.com"
                    style={{ cursor: "pointer" }}
                  >
                    shreeumiyatours2013@gmail.com
                  </a>
                </li>
                <li>+91 99744 08008</li>
                <li>+91 97143 93617</li>
                <li>+91 97732 11424</li>
                <li>+91 97378 93617</li>
                <p>
                  91, Hirabag Society Part -1, Near C.P Nagar Cross
                  Road,Ghatlodiya, Ahemdabad 380061
                </p>
              </ul>
            </li>
            <li>
              <p className="footer_header">Map Location</p>
              <ul className="box">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.933177221015!2d72.5381237!3d23.062911299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e830d8d76676b%3A0x6e9644ccbbb6e013!2sShree%20umiya%20tourism!5e0!3m2!1sen!2sin!4v1668854905299!5m2!1sen!2sin"
                  // <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  width="600"
                  title="loction"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </ul>
            </li>
          </ul>
        </div>
        <div className="b-footer">
          <p>@umiyatourism2023, All rights reserved.</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
