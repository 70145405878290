import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminLogin } from "../../Service/admin.api.service";
import "./Login.css";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [passWord, setPassWord] = useState("");

  const Submithandler = () => {
    setEmail("");
    setPassWord("");
    EnterHandler(email, passWord);
  };

  const EnterHandler = async (email, passWord) => {
    const data = {
      loginId: email,
      password: passWord,
    };
    const Response = await AdminLogin(data);
    if (Response.data.code == 200) {
      localStorage.setItem("id", Response.data.data.id);
      await navigate("/subcategories");
    } else {
    }
  };

  return (
    <>
      <div className="login-box">
        <h3>Login</h3>
        <p>Email</p>
        <input
          type="email"
          value={email}
          name=""
          placeholder="Enter your username..."
          onChange={(e) => setEmail(e.target.value)}
        />
        <p>Password</p>
        <input
          type="password"
          name=""
          value={passWord}
          placeholder="Enter your password..."
          onChange={(e) => setPassWord(e.target.value)}
        />
        <input type="button" name="" value="Login" onClick={Submithandler} />
      </div>
    </>
  );
}

export default Login;
