import React from "react";
import { Link } from "react-router-dom";
import { Rating } from "@mui/material";
import { scrollTop } from "../../utils/helper";
import "./PackageCard.css";
function PackageCard(props) {
  return (
    <>
      <Link
        onClick={scrollTop}
        className="card_container_column cursor-point"
        to={`/packagedetail/${props.data.id}`}
      >
        <div>
          <img
            className="card_container_img"
            src={props?.data?.tittleImageUrl}
            alt=""
          />
          <div className="price_box_container_Package">
            <div className="price_box">
              <p>₹{props?.data?.price}</p>
            </div>
          </div>
        </div>
        <div className="text_container_Package_card">
          <p className="box_text_1">{props?.data?.tittle}</p>
          <p className="box_text_2">{props?.data?.description}</p>
          <Rating
            name="half-rating-read"
            defaultValue={props?.data?.avgReview}
            precision={0.5}
            readOnly
          />
        </div>
      </Link>
    </>
  );
}

export default PackageCard;
