import React, { useEffect, useState } from "react";
import AdminDataTable from "../../component/AdminDataTable";
import {
  DeleteHotelData,
  GetAllHotelData,
} from "../../Service/admin.api.service";
import DeleteIcon from "../../assets/image/delete.png";
import EditIcon from "../../assets/image/edit.png";
import { useNavigate } from "react-router-dom";
function HotelData() {
  const navigate = useNavigate();
  const [hotelData, setHotelData] = useState("");

  const columData = [
    {
      label: "name",
    },
    {
      label: "location ",
    },
    {
      label: "price",
    },
    {
      label: "action",
    },
  ];

  const DataHandler = async () => {
    const Data = await GetAllHotelData();
    setHotelData(Data.data);
  };

  useEffect(() => {
    DataHandler();
  }, []);

  const EdiHandler = (id) => {
    navigate(`/createhotel/${id}`);
  };

  const DeleteHandler = async (id) => {
    const response = await DeleteHotelData(id);
  };

  return (
    <>
      {hotelData && (
        <AdminDataTable
          tableTitleData={columData}
          TableData={hotelData.map((d, index) => {
            return (
              <tr className="text_center" key={`hotelData${index}`}>
                <td>{d.name}</td>
                <td>{d.location}</td>
                <td>{d.price}</td>
                <td>
                  <div className="table_Icon_admin">
                    <img
                      src={EditIcon}
                      alt=""
                      width="30px"
                      height="30px"
                      style={{ cursor: "pointer" }}
                      onClick={() => EdiHandler(d.id)}
                    />
                    <img
                      src={DeleteIcon}
                      alt=""
                      width="30px"
                      height="30px"
                      style={{ cursor: "pointer" }}
                      onClick={() => DeleteHandler(d.id)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        />
      )}
    </>
  );
}

export default HotelData;
