import React, { useState, useEffect } from "react";

function PackageDetailsInfo({ data }) {
  return (
    <div className="table_container_column">
      <table className="package_table" style={{ width: "100%" }}>
        <tr>
          <th>Pickup Point</th>
          <td>{data.pickup}</td>
        </tr>
        <tr>
          <th>Duration</th>
          <td>{`${new Date(data.startDate).getDate()}/${
            new Date(data.startDate).getMonth() + 1
          }/${new Date(data.startDate).getFullYear()}  To  ${new Date(
            data.endDate
          ).getDate()}/${new Date(data.endDate).getMonth() + 1}/${new Date(
            data.endDate
          ).getFullYear()} `}</td>
        </tr>
        <tr>
          <th>Inclusion</th>
          <td className="td_spacing_text">
            {data?.PackageInclusionExclusion?.filter(
              (data) => data.type === "INCLUSION"
            ).map((data, index) => (
              <li key={`INCLUSION${index}`}> {data.name}</li>
            ))}
          </td>
        </tr>
        <tr>
          <th>Exclusion</th>
          <td className="td_spacing_text">
            {data?.PackageInclusionExclusion?.filter(
              (data) => data.type === "EXCLUSION"
            ).map((data, index) => (
              <li key={`EXCLUSION${index}`}> {data.name}</li>
            ))}
          </td>
        </tr>
        <tr>
          <th>Stay</th>
          <td>{data.stay}</td>
        </tr>
      </table>
    </div>
  );
}

export default PackageDetailsInfo;
