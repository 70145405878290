import React from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
function Sidebar() {
  const logOutHandler = () => {
    localStorage.clear();
  };
  return (
    <>
      <ul className="sidebar">
        <NavLink
          to="/subcategories"
          className={({ isActive }) => (isActive ? "sidebar-active" : "")}
        >
          <li> SubCategory </li>
        </NavLink>
        <NavLink
          to="/package"
          className={({ isActive }) => (isActive ? "sidebar-active" : "")}
        >
          <li> Package </li>
        </NavLink>
        <NavLink
          to="/hotel"
          className={({ isActive }) => (isActive ? "sidebar-active" : "")}
        >
          <li> Hotel </li>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "sidebar-active" : "")}
          to="/admin/car-rental"
        >
          <li> Car Rental </li>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "sidebar-active" : "")}
          to="/customerinqury"
        >
          <li> Customer Inquiry </li>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "sidebar-active" : "")}
          to="/admin/contactus"
        >
          <li> Contact Us </li>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "sidebar-active" : "")}
          to="/admin/review"
        >
          <li> Reviews </li>
        </NavLink>
        <NavLink to="/admin">
          <li className="cursor-point" onClick={() => logOutHandler()}>
            Log Out
          </li>
        </NavLink>
      </ul>
    </>
  );
}

export default Sidebar;
