import React, { useEffect, useState } from "react";
import Footer from "../../comman/Footer";
import Header from "../../comman/Header";
import CarRentalCard from "../../component/CarRentalCard";
import carImage from "../../assets/image/FinalCarRental.png";
import HomeHeroBanner from "../../component/HomeHeroBanner";
import { GetAllCarData } from "../../Service/api.sevice";
import "./CarRental.css";
function CarRental() {
  const [carRentalData, setCarRentalData] = useState([]);

  const GetData = async () => {
    const Response = await GetAllCarData();
    setCarRentalData(Response.data);
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <>
      <Header />
      <HomeHeroBanner Image={carImage} Text={`Car/Bus Rental`} />
      <section className="center_container">
        <div className="car_section_row">
          {carRentalData?.map((data, index) => {
            return (
              <CarRentalCard data={data} key={`car_section_row${index}`} />
            );
          })}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default CarRental;
