import React from "react";
import { Link } from "react-router-dom";
import "./Information.css";
function Information() {
  return (
    <>
      <section className="center_container">
        <div className="section_2">
          <div className="section_2_column left_column">
            <div>
              <p className="sec_2_text_1">About Umiya Tourism</p>
              <p className="sec_2_text_2">
                Best Travel Agency
                <br />
                Company
              </p>
            </div>
          </div>
          <div className="section_2_column righ_column">
            <div>
              <p className="sec_2_text_3">
              We are a team of professionals driven by a desire to change the whole landscape of Travel and
              Tourism in the region,We offer unique range of
              Tourism products from General Tourism, Adventure Tourism, Film Tourism, Corporate
              Travel, and Incentive Tourism to Event Management
              </p>
              <Link to="/aboutus">
                <button className="read_more">READ MORE</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Information;
