import React, { useEffect, useState } from "react";
import "./Testimonials.css";
import { Navigation, Pagination, Scrollbar, A11y, Controller } from "swiper";
import navLeft from "../../assets/image/nav_left.png";
import navRight from "../../assets/image/nav_right.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import TestimonialsCard from "./TestimonialsCard";
import { GetUserReview } from "../../Service/api.sevice";

function Testimonials() {
  const [swiper, setSwiper] = React.useState();
  const [userReviewData, setUserReviewData] = useState([]);
  const prevRef = React.useRef();
  const nextRef = React.useRef();

  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  useEffect(() => {
    ReviewHandler();
  }, []);

  const ReviewHandler = async () => {
    const response = await GetUserReview();
    setUserReviewData(response.data);
  };

  const totalColor = [
    "#FB9912",
    "#196587",
    "#C71918",
    "#FB9912",
    "#196587",
    "#C71918",
    "#FB9912",
    "#196587",
    "#C71918",
  ];

  const sliderBreakPoint = {
    1920: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1300: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    901: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    630: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    321: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
  };

  return (
    <>
      <section className="testimonial_container">
        <div className="testimonoal_heading_container">
          <div className="heading_column heading_left_column">
            <div>
              <p className="Testi_monials_heading">Testimonials</p>
              <p className="Testi_monials_subTitle">Client’s Comments</p>
            </div>
          </div>
          <div className="heading_column heading_righ_column">
            <button className="arrow_left" ref={prevRef}>
              <img
                src={navLeft}
                alt="left_navigation"
                className="arrow-image"
              />
            </button>
            <button className="arrow_right" ref={nextRef}>
              <img
                src={navRight}
                alt="right_navigation"
                className="arrow-image"
              />
            </button>
          </div>
        </div>
        <section className="swiper_container">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
            spaceBetween={50}
            breakpoints={sliderBreakPoint}
            navigation={{
              prevEl: prevRef?.current,
              nextEl: nextRef?.current,
            }}
            updateOnWindowResize
            observer
            observeParents
            onSwiper={setSwiper}
          >
            {userReviewData?.map((data, i) => {
              return (
                <SwiperSlide key={`userReviewData${i}`}>
                  <TestimonialsCard data={data} color={totalColor[i]} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
      </section>
    </>
  );
}

export default Testimonials;
