import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Rating from "@mui/material/Rating";
import $ from "jquery";
import { User } from "../../utils/Data";
import "./ReviewPopUp.css";
import imageUpolad from "../../assets/image/Group 315.png";
import { UserReview } from "../../Service/api.sevice";
import { errorToast } from "../../utils/helper";
import { EMAIL_REGEX } from "../../utils/validation";

function ReviewPopUp(props) {
  const [value, setValue] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState("");
  const [Image, setImage] = useState("");
  const [responseCode, setResponseCode] = useState();
  const [emailError, setEmailError] = useState();
  const [mobileError, setMobileError] = useState();
  const [displayImage, setDisplayImage] = useState("");

  const Validation = () => {
    let formValid = true;
    if (name.trim() && email.trim() && mobileNumber.trim()) {
      if (!EMAIL_REGEX.test(email) === true) {
        formValid = false;
        setEmailError("Please Enter Valid Email Address");
      }
      if (mobileNumber.length !== 10) {
        formValid = false;
        setMobileError("Please Enter valid Mobile Number");
      }
    } else {
      formValid = false;
      errorToast("Please Enter Details");
    }
    return formValid;
  };

  const userReviewHandler = async () => {
    if (Validation()) {
      if (props.PackageId) {
        const formData = new FormData();
        formData.append("userId", User.userId);
        formData.append("packageId", props.PackageId);
        formData.append("name", name);
        formData.append("imageUrl", Image);
        formData.append("star", value);
        formData.append("message", message);

        const Response = await UserReview(formData);
        setResponseCode(Response.data.code);
      } else {
        errorToast("Something Went Wrong");
      }
    } else {
      // errorToast("Something Went Wrong");
    }
  };

  const imageHandler = () => {
    $("#chooce_picture").click();
  };

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Modal onHide={() => handleClose()} show={props.isOpen} size="lg" centered>
      {responseCode === 200 ? (
        <div className="Center_userReview_Box">
          <h1> {`Thank You !`} </h1>
          <p
            dangerouslySetInnerHTML={{
              __html: `Thanks a bunch for filling that out. It means a lot to us, just
                like <b> ${name}  </b> you do! We really appreciate you giving us a moment of your
                time today. Thanks for being you`,
            }}
          ></p>
        </div>
      ) : (
        <div className="modal_grid">
          <div className="Center_userReview_Box">
            <p className="USER_PopHeader">{`Give Some Review`}</p>
            {displayImage ? (
              <img
                src={displayImage}
                className="User_Profile_Picture"
                alt="user_Image"
                onClick={() => imageHandler()}
              />
            ) : (
              <img
                className="User_picture"
                src={imageUpolad}
                alt=""
                onClick={() => imageHandler()}
              />
            )}
            <input
              type="file"
              id="chooce_picture"
              className="hidden_input"
              placeholder="upload picture"
              onChange={(e) => [
                setImage(e.target.files[0]),
                setDisplayImage(URL.createObjectURL(e.target.files[0])),
              ]}
            />
          </div>
          <input
            id="outlined-basic"
            fullWidth
            className="create_package_field"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <input
            id="outlined-basic"
            className="create_package_field"
            onChange={(e) => setMobileNumber(e.target.value)}
            fullWidth
            placeholder="MobileNumber"
            variant="outlined"
            margin="normal"
          />
          {mobileError && <p style={{ color: "red" }}> {mobileError}</p>}
          <input
            id="outlined-basic"
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="create_package_field"
            variant="outlined"
            margin="normal"
          />
          {emailError && <p style={{ color: "red" }}> {emailError}</p>}
          <input
            id="outlined-basic"
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            placeholder="Type your Message Here"
            className="create_package_field"
            variant="outlined"
            margin="normal"
          />
          <Rating
            name="simple-controlled"
            value={value}
            size="large"
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
          <button className="submit_button" onClick={userReviewHandler}>
            Submit
          </button>
        </div>
      )}
    </Modal>
  );
}

export default ReviewPopUp;
