import React from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
function AdminDataTable(props) {
  $(document).ready(function () {
    $("#example").DataTable();
  });
  return (
    <>
      <table id="example" className="cell-border stripe">
        <thead>
          <tr>
            {props.tableTitleData.map((Data, index) => {
              return <th key={`tableTitleData${index}`}>{Data.label}</th>;
            })}
          </tr>
        </thead>
        <tbody>{props.TableData}</tbody>
      </table>
    </>
  );
}

export default AdminDataTable;
