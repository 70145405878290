import React, { useState } from "react";
import Sidebar from "../Sidebar";
import PackageDetail from "./PackageDetail";
import { Link, NavLink } from "react-router-dom";
function Package() {
  const [createPackageOpen, setCreatePackageOpen] = useState(false);
  return (
    <>
      <div className="admin_main_section">
        <Sidebar />
        <div className="admin_start">
          <div className="heading_div"></div>
          <div className="from_Top">
            <NavLink to="/createpackage">
              <button className="submit_Btn">Create Package</button>
            </NavLink>
          </div>
          <PackageDetail />
        </div>
      </div>
    </>
  );
}

export default Package;
