import { ENDPOINTURL } from "../utils/helper";
import { DELETE, get, post, put } from "../webRequest";
import { User } from "../utils/Data";
export const AdminLogin = (data) => {
  return post(`${ENDPOINTURL}/auth`, data);
};

// create package
export const CreatePackageData = (data) => {
  return post(`${ENDPOINTURL}/package`, data);
};

// Add package Gallery
export const AddPackageData = (data) => {
  return post(`${ENDPOINTURL}/package/image`, data);
};

// Update Package
export const UpdatePackage = (data) => {
  return put(`${ENDPOINTURL}/package`, data);
};

// edit Package By Id
export const EditPackageByEdit = (id) => {
  return get(`${ENDPOINTURL}/package/${id}`);
};

//  delete ImageGallery
export const DeletePackageImageById = (id) => {
  return DELETE(`${ENDPOINTURL}/package/image/${id}`);
};

// create car
export const CreateCar = () => {
  return post(`${ENDPOINTURL}/car`);
};

// get all hotel
export const GetAllHotelData = () => {
  return get(`${ENDPOINTURL}/hotel/${User.userId}`);
};

//  create hotel

export const CreateHotelData = (data) => {
  return post(`${ENDPOINTURL}/hotel`, data);
};

// create Hotel Image
export const CreateHotelImage = (data) => {
  return post(`${ENDPOINTURL}/hotel/images`, data);
};

// edit hotel By Id
export const EditHotelData = (id) => {
  return get(`${ENDPOINTURL}/hotel/hotel/${id}`);
};

// edit Hotel by data
export const EditHotelByData = (data) => {
  return put(`${ENDPOINTURL}/hotel`, data);
};

// delete hotel

export const DeleteHotelData = (id) => {
  return DELETE(`${ENDPOINTURL}/hotel/${id}`);
};

export const DeleteHotelImage = (id) => {
  return DELETE(`${ENDPOINTURL}/hotel/images/${id}`);
};

// get Customer Inquiry
export const GetCustomerInquiry = () => {
  return get(`${ENDPOINTURL}/contact/inquiry/${User.userId}`);
};

// submit Customer Inquiry
export const CustomerInquiryForm = (data) => {
  return post(`${ENDPOINTURL}/contact/inquiry`, data);
};

export const GetContactUs = (id) => {
  return get(`${ENDPOINTURL}/contact/${id}`);
};

export const GetReviewAdmin = (id, count) => {
  return get(`${ENDPOINTURL}/contact/review/${id}?count=${count}`);
};

export const DeleteReview = (id) => {
  return DELETE(`${ENDPOINTURL}/contact/review/${id}`);
};

// GetSubCategoreyBycateGorey

export const GetSubCatBycategorey = (id) => {
  return get(`${ENDPOINTURL}/subcategory/category/${id}`);
};

export const GetSubCategory = (userId) => {
  return get(`${ENDPOINTURL}/subcategory/${userId}`);
};

export const DeleteSubCategory = (id) => {
  return DELETE(`${ENDPOINTURL}/subcategory/${id}`);
};

export const CreateSubCategory = (data) => {
  return post(`${ENDPOINTURL}/subcategory/`, data);
};

export const EditSubCategory = (data) => {
  return put(`${ENDPOINTURL}/subcategory/`, data);
};

export const AddItineary = (data) => {
  return put(
    `${ENDPOINTURL}/package/${data.itinerary[0].packageId}/itinerary`,
    data
  );
};

export const AddIncExc = (data) => {
  return put(`${ENDPOINTURL}/package/${data.incExc[0].packageId}/incexc`, data);
};

export const DeleteCarById = (id) => {
  return DELETE(`${ENDPOINTURL}/car/${id}`);
};

export const CreateCarData = (data) => {
  return post(`${ENDPOINTURL}/car`, data);
};

export const CreateVehicleImage = (body) => {
  return post(`${ENDPOINTURL}/car/images`, body);
};

export const EditVehicleById = (id) => {
  return get(`${ENDPOINTURL}/car/car/${id}`);
};

export const EditVehileData = (data) => {
  return put(`${ENDPOINTURL}/car/`, data);
};

export const DeleteCarImage = (id) => {
  return DELETE(`${ENDPOINTURL}/car/images/${id}`);
};

export const setPackagePopular = (id, status) => {
  return put(`${ENDPOINTURL}/package/popular`, {
    packageId: id,
    status: status,
  });
};

export const setUpcomingpackage = (id, status) => {
  return put(`${ENDPOINTURL}/package/upcoming`, {
    packageId: id,
    status: status,
  });
};

export const setIsActive = (id, status) => {
  return put(`${ENDPOINTURL}/package/status`, {
    packageId: id,
    isActive: status,
  });
};

// get Package Details

export const GetAllPackageDetails = (id) => {
  return get(`${ENDPOINTURL}/package/${id}`);
};

export const EditPackageById = () => {
  // return;
};
