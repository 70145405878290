import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import CarRentalData from "./CarRentalData";

function CarRental() {
  return (
    <div className="admin_main_section">
      <Sidebar />
      <div className="admin_start">
        <div className="heading_div">
          <h1> Car-Rental </h1>
        </div>
        <div>
          <Link to="/admin/createcar">
            <button className="submit_Btn">Add vehicle</button>
          </Link>
        </div>
        <CarRentalData />
      </div>
    </div>
  );
}

export default CarRental;
