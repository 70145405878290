import React, { useState } from "react";
import Header from "../../comman/Header";
import contact_bg from "../../assets/image/contact_bg.jpg";
import HomeHeroBanner from "../../component/HomeHeroBanner";
import Footer from "../../comman/Footer";
import { User } from "../../utils/Data";
import "./Inquriy.css";
import { useLocation } from "react-router-dom";
import { CustomerInquiryForm } from "../../Service/admin.api.service";
import { errorToast } from "../../utils/helper";

function InquriyForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [numberOfMember, setNumberOfMember] = useState("");

  const location = useLocation();
  const { hotelId } = location.state;
  const { packageId } = location.state;
  const { carId } = location.state;

  const SubmitHandler = async () => {
    if (
      name.trim() &&
      email.trim() &&
      numberOfMember.trim() &&
      mobileNumber.trim()
    ) {
      const Data = {
        packageId: packageId,
        hotelId: hotelId,
        carId: carId,
        userId: User.userId,
        name: name,
        email: email,
        memberNo: numberOfMember,
        mobile: mobileNumber,
      };

      const response = await CustomerInquiryForm(Data);
      if (response.data.code === 200) {
        setName("");
        setEmail("");
        setMobileNumber("");
        setNumberOfMember("");
      }
    } else {
      errorToast("plese Fill Up Data");
    }
  };
  return (
    <>
      <Header />
      <HomeHeroBanner Image={contact_bg} Text={` Do you want inQuriy ?`} />
      <section>
        <div className="contact_container">
          <form>
            <div className="row">
              <div className="cols-form">
                <input
                  type="text"
                  id="fname"
                  className="contact-input"
                  name="firstname"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="cols-form">
                <input
                  type="email"
                  placeholder="Email Id"
                  className="contact-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="cols-form">
                <input
                  type="number"
                  className="contact-input"
                  placeholder="Number of Members"
                  value={numberOfMember}
                  onChange={(e) => setNumberOfMember(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="cols-form">
                <input
                  placeholder="Mobile Number"
                  className="contact-input"
                  type="number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </div>
            </div>
          </form>
          <div className="row">
            <div className="submit_btn for_top">
              <input type="submit" onClick={SubmitHandler} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default InquriyForm;
