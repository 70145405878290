import React, { useState } from "react";

function Itinerary({ que, ans }) {
  const [active, setactive] = useState(false);
  return (
    <>
      <button className="collapsible" onClick={() => setactive(!active)}>
        {`Day ${que}`}
      </button>
      {active && <p>{ans}</p>}
    </>
  );
}

export default Itinerary;
