import React, { useEffect, useState } from "react";
import {
  CreateCarData,
  CreateVehicleImage,
  DeleteCarImage,
  EditVehicleById,
  EditVehileData,
} from "../../Service/admin.api.service";
import { User } from "../../utils/Data";
import TextField from "@mui/material/TextField";
import Sidebar from "../Sidebar";
import $ from "jquery";
import ADDImage from "../../assets/image/add-image .png";
import { errorToast } from "../../utils/helper";
function AdminCreatecar() {
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [price, setPrice] = useState("");
  const [imageToDisplay, setImageToDisplay] = useState("");
  const [id, setId] = useState("");
  const [childVehicleImage, setChildVehicleImage] = useState("");

  const Datahandler = async () => {
    if (name.trim() && imageUrl && price.trim()) {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("userId", User.userId);
      formData.append("name", name);
      formData.append("titleImageUrl", imageUrl ? imageUrl : null);
      formData.append("price", price);

      if (!id) {
        const response = await CreateCarData(formData);
        setId(response.data.data.id);
      }

      if (id) {
        const EditData = await EditVehileData(formData);
      }
    } else {
      errorToast("please fill up data");
    }
  };

  const deleteVehicleImage = async (id) => {
    const Response = await DeleteCarImage(id);
  };

  const ImageUpload = async (e) => {
    const ImageData = new FormData();
    ImageData.append("carId", id);
    ImageData.append("imageUrl", e.target.files[0]);

    const response = await CreateVehicleImage(ImageData);
    if (response) {
      EditHandler(response.data.data.carRentalId);
    }
  };

  const EditHandler = async (Id) => {
    const Response = await EditVehicleById(Id);
    setName(Response.data.name);
    setImageUrl(Response.data.imageUrl);
    setImageToDisplay(Response.data.imageUrl);
    setPrice(Response.data.price);
    setId(Response.data.id);
    setChildVehicleImage(Response.data.CarRentalImages);
  };

  const ImageHandler = () => {
    $("#chooce_picture").click();
  };

  useEffect(() => {
    const Id = window.location.pathname.split(`/`)[3];
    if (Id) {
      setId(Id);
      EditHandler(Id);
    }
  }, []);

  return (
    <>
      <div className="admin_main_section">
        <Sidebar />
        <div className="admin_start">
          <div className="heading_div"></div>
          <div className="for_top">
            <div>
              <TextField
                type="text"
                className="create_package_field"
                placeholder="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="for_top">
              <label> image : </label>
              <br />
              {imageToDisplay && (
                <img
                  src={imageToDisplay}
                  width="100px"
                  height="100px"
                  alt="Image"
                />
              )}
              <input
                type="file"
                placeholder="Vehicle Title Image"
                onChange={(e) => [
                  setImageUrl(e.target.files[0]),
                  setImageToDisplay(URL.createObjectURL(e.target.files[0])),
                ]}
              />
            </div>
          </div>
          <div>
            <div>
              <label> Price </label>
              <TextField
                type="number"
                className="create_package_field"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              style={{ marginTop: "20px" }}
              className="submit_Btn"
              onClick={() => Datahandler()}
            >
              {" "}
              submit{" "}
            </button>
          </div>
          {id && (
            <div className="image_section_start">
              <div className="add_image_section">
                {childVehicleImage &&
                  childVehicleImage.length > 0 &&
                  childVehicleImage.map((data, index) => {
                    return (
                      <div key={`image_section_start1${index}`}>
                        <img
                          src={data.imageUrl}
                          alt=""
                          width="150px"
                          height="200px"
                        />
                        <button onClick={() => deleteVehicleImage(data.id)}>
                          delete
                        </button>
                      </div>
                    );
                  })}
                <img
                  src={ADDImage}
                  alt="image"
                  style={{ cursor: "pointer" }}
                  onClick={() => ImageHandler()}
                />
                <input
                  type="file"
                  className="Image_Input"
                  id="chooce_picture"
                  style={{ display: "none" }}
                  onChange={ImageUpload}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminCreatecar;
