import "./App.css";
import Home from "./Page/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "./Page/AboutUs";
import ContactUs from "./Page/ContactUs";
import Domestic from "./Page/Domestic";
import International from "./Page/International";
import Hotel from "./Page/Hotel";
import GroupTour from "./Page/GroupTour";
import Carrental from "./Page/CarRental";
import PackageDetail from "./Page/PackageDetail";
import Login from "./component/Login";
import SubCategories from "./Admin/SubCategories";
import Protected from "./utils/Protected";
import PageNotFound from "./Page/PageNotFound";
import Package from "./Admin/Package/index";
import AdminHotel from "./Admin/AdminHotel";
import CustomerInquiry from "./Admin/CustomerInquiry";
import Inquriy from "./Page/InquriyForm";
import CreateHotel from "./Admin/AdminHotel/CreateHotel";
import AdminContactUs from "./Admin/AdminContactUs";
import AdminReviews from "./Admin/AdminReviews";
import { toast } from "react-toastify";
import CreatePackage from "./Admin/Package/CreatePackage";
import CarRental from "./Admin/Car-Rental";
import AdminCreatecar from "./Admin/Car-Rental/AdminCreatecar";
import PopularPackage from "./Page/PopularPackage";
import UpComingPackage from "./Page/Upcoming";
function App() {
  toast.configure();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/domesticpackage" element={<Domestic />} />
        <Route path="/internationalpackage" element={<International />} />
        <Route path="/inquiry" element={<Inquriy />} />
        <Route path="/grouptour" element={<GroupTour />} />
        <Route path="/hotelbooking" element={<Hotel />} />
        <Route path="/rental" element={<Carrental />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/popular" element={<PopularPackage />} />
        <Route path="/upcoming" element={<UpComingPackage />} />
        <Route path="/packagedetail/:id" element={<PackageDetail />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/admin" element={<Login />} />
        <Route element={<Protected />}>
          <Route path="/subcategories" element={<SubCategories />} />
          <Route path="/package" element={<Package />} />
          <Route path="/createpackage" element={<CreatePackage />} />
          <Route path="createpackage/:id" element={<CreatePackage />} />
          <Route path="/hotel" element={<AdminHotel />} />
          <Route path="/createhotel" element={<CreateHotel />} />
          <Route path="/createhotel/:id" element={<CreateHotel />} />
          <Route path="/customerinqury" element={<CustomerInquiry />} />
          <Route path="/admin/contactus" element={<AdminContactUs />} />
          <Route path="/admin/review" element={<AdminReviews />} />
          <Route path="/admin/car-rental" element={<CarRental />} />
          <Route path="/admin/createcar" element={<AdminCreatecar />} />
          <Route path="/admin/createcar/:id" element={<AdminCreatecar />} />
          {/* protect router will be here  */}
        </Route>
        <Route path="*" element={<PageNotFound />} status={404} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
