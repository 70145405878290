import React from "react";
import NavRight from "../../assets/image/nav_right._white.svg";
import ModalSlider from "../ModalSlider";
import "./HotelCard.css";
function HotelCard({ Data }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const ModalCloseHandler = () => {
    setOpen(false);
  };
  return (
    <div className="hotel_card_container_column">
      <div>
        <img
          className="Hotel_card_container_img"
          src={Data.imageUrl}
          alt="HotelImg"
          width="333px"
          height="280px"
        />
        <div className="price_box_container_hotel">
          <div className="price_box">
            <p>{`₹${Data.price}`}</p>
          </div>
        </div>
      </div>
      <div className="text_container">
        <div className="hotelText_div">
          <p className="box_text_1">
            {Data.name} , {Data.location}
          </p>
        </div>
        <div className="car_inquiry_box" onClick={() => handleOpen()}>
          <p className="view_more_text">View More</p>
          <img src={NavRight} alt="" width="50px" height="20px" />
        </div>
      </div>
      <ModalSlider
        isOpen={open}
        handleClose={ModalCloseHandler}
        Tittle={Data.name}
        Images={Data.HotelImages}
        Id={Data.id}
      />
    </div>
  );
}

export default HotelCard;
