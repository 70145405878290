import React, { useEffect, useState } from "react";
import Footer from "../../comman/Footer";
import Header from "../../comman/Header";
import "./hotel.css";
import { GetAllHotelData } from "../../Service/admin.api.service";
import HomeHeroBanner from "../../component/HomeHeroBanner";
import Hotelbackground from "../../assets/image/hotel_Final_.jpg";
import HotelCard from "../../component/HotelCard";

function Hotel() {
  const [hotelData, setHotelData] = useState("");

  const hotelDataHandler = async () => {
    const response = await GetAllHotelData();
    setHotelData(response.data);
  };

  useEffect(() => {
    hotelDataHandler();
  }, []);

  return (
    <>
      <Header />
      <HomeHeroBanner Image={Hotelbackground} />
      <section className="center_container">
        <div className="hotel_card_container">
          {hotelData &&
            hotelData.map((data, index) => {
              return (
                <HotelCard Data={data} key={`hotel_card_container${index}`} />
              );
            })}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Hotel;
