import React, { useState } from "react";
import Footer from "../../comman/Footer";
import Header from "../../comman/Header";
import HomeHeroBanner from "../../component/HomeHeroBanner";
import contact_bg from "../../assets/image/contact_bg.jpg";
import { ContactData } from "../../Service/api.sevice";
import { User } from "../../utils/Data";
import "./ContactUs.css";
import { errorToast } from "../../utils/helper";
function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const SubmitHandler = async () => {
    if (name.trim() && email.trim() && mobileNumber && message.trim()) {
      const Data = {
        userId: User.userId,
        name: name,
        email: email,
        mobile: mobileNumber,
        message: message,
      };

      const response = await ContactData(Data);
      if (response.data.code === 200) {
        setName("");
        setEmail("");
        setMessage("");
        setMobileNumber("");
      }
    } else {
      errorToast("Please Fill Up Data");
    }
  };

  return (
    <>
      <Header />
      <HomeHeroBanner Image={contact_bg} Text={`Contact Us`} />
      <section>
        <div className="contact_container">
          <form>
            <div className="row">
              <div className="cols-form">
                <input
                  type="text"
                  id="fname"
                  className="contact-input"
                  name="firstname"
                  placeholder="First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="cols-form">
                <input
                  type="email"
                  placeholder="Email Id"
                  className="contact-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="cols-form">
                <input
                  type="number"
                  className="contact-input"
                  placeholder="Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="cols-form">
                <textarea
                  placeholder="Your Message"
                  className="contact-input"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="submit_btn">
              <input type="submit" onClick={SubmitHandler} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ContactUs;
