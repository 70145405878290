import React from "react";
import Sidebar from "../Sidebar";
import InquiryDetails from "./InquiryDetails";

function CustomerInquiry() {
  return (
    <>
      <div className="admin_main_section">
        <Sidebar />
        <div className="admin_start">
          <div className="heading_div">
            <h1> Customer Inquiry </h1>
          </div>
          <InquiryDetails />
        </div>
      </div>
    </>
  );
}

export default CustomerInquiry;
