import React, { useState, useEffect } from "react";
import {
  CreateHotelData,
  CreateHotelImage,
  DeleteHotelImage,
  EditHotelByData,
  EditHotelData,
} from "../../Service/admin.api.service";
import { User } from "../../utils/Data";
import Sidebar from "../Sidebar";
import ADDImage from "../../assets/image/add-image .png";
import $ from "jquery";
import "./Admin.css";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { errorToast } from "../../utils/helper";
function CreateHotel() {
  const [name, setName] = useState("");
  const [imageUrl, setImageurl] = useState("");
  const [locaion, setLocation] = useState("");
  const [price, setPrice] = useState("");
  const [ImageToDisplay, setImageToDisplay] = useState("");
  const [id, setId] = useState("");
  const [childHotelImage, setChildHotelImage] = useState("");

  const Datahandler = async () => {
    if (name.trim() && imageUrl && locaion.trim() && price) {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("userId", User.userId);
      formData.append("name", name);
      formData.append("titleImageUrl", imageUrl ? imageUrl : null);
      formData.append("location", locaion);
      formData.append("price", price);

      if (!id) {
        const response = await CreateHotelData(formData);

        if (response.data.code === 200) {
          setId(response.data.data.id);
        }
      }

      if (id) {
        const EditDataResponse = await EditHotelByData(formData);
      }
    } else {
      errorToast("please fill data");
    }
  };

  const EditHandler = async (Id) => {
    const Response = await EditHotelData(Id);
    setName(Response.data.name);
    setImageurl(Response.data.imageUrl);
    setImageToDisplay(Response.data.imageUrl);
    setLocation(Response.data.location);
    setPrice(Response.data.price);
    setId(Response.data.id);
    setChildHotelImage(Response.data.HotelImages);
  };

  useEffect(() => {
    const Id = window.location.pathname.split(`/`)[2];
    if (Id) {
      EditHandler(Id);
    }
  }, []);

  const ImageHandler = () => {
    $("#chooce_picture").click();
  };

  const DeleteChildImage = async (id) => {
    const Response = await DeleteHotelImage(id);
  };

  const ImageUpload = async (e) => {
    const ImageData = new FormData();
    ImageData.append("hotelId", id);
    ImageData.append("imageUrl", e.target.files[0]);
    const response = await CreateHotelImage(ImageData);
    if (response) {
      EditHandler(response.data.data.hotelId);
    }
  };

  return (
    <>
      <div className="admin_main_section">
        <Sidebar />
        <div className="admin_start">
          <div className="heading_div"></div>
          <div>
            <div>
              <label> titleImage Url</label>
              <br />
              {ImageToDisplay && (
                <img src={ImageToDisplay} alt="" width="200px" height="200px" />
              )}
              <input
                type="file"
                placeholder="Tittle url"
                onChange={(e) => [
                  setImageurl(e.target.files[0]),
                  setImageToDisplay(URL.createObjectURL(e.target.files[0])),
                ]}
              />
            </div>
            <div>
              <label> Hotel Name : </label>
              <input
                type="text"
                className="create_package_field"
                placeholder="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div>
              <label> Location </label>
              <input
                type="text"
                className="create_package_field"
                placeholder="Location"
                value={locaion}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div>
              <label> Price </label>
              <input
                type="number"
                className="create_package_field"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              className="submit_Btn from_Top"
              onClick={() => Datahandler()}
            >
              submit
            </button>
          </div>
          {id && (
            <div className="image_section_start">
              <div className="add_image_section">
                {childHotelImage &&
                  childHotelImage.length > 0 &&
                  childHotelImage.map((data, index) => {
                    return (
                      <div key={`add_image_section${index}`}>
                        <img
                          src={data.imageUrl}
                          alt=""
                          width="150px"
                          height="200px"
                        />
                        <button onClick={() => DeleteChildImage(data.id)}>
                          delete
                        </button>
                      </div>
                    );
                  })}
                <img
                  src={ADDImage}
                  alt="image"
                  style={{ cursor: "pointer" }}
                  onClick={() => ImageHandler()}
                />
                <TextField
                  type="file"
                  size="small"
                  fullWidth
                  className="Image_Input"
                  id="chooce_picture"
                  style={{ display: "none" }}
                  onChange={ImageUpload}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateHotel;
