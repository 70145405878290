import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetAllPackage } from "../../Service/api.sevice";
import { scrollTop } from "../../utils/helper";
import PackageCard from "../PackageCard";
import "./UpComing.css";
function UpComingPackage() {
  const [packageData, setPackageData] = useState("");

  const getPackageData = async () => {
    const getAllPackage = await GetAllPackage("upcoming", 3);
    setPackageData(getAllPackage.data);
  };

  useEffect(() => {
    getPackageData();
  }, []);

  return (
    <section className="upcoming_tips_container center_container">
      <section className="center_container">
        <div className="heading_container_upcoming">
          <div className="heading_column heading_left_column">
            <div>
              <p className="heading_text_1">What we serve</p>
              <p className="heading_text_2">Upcoming Trips</p>
            </div>
          </div>
          <Link to="/upcoming" onClick={scrollTop}>
            <div className="heading_column heading_righ_column_upcoming">
              <button className="read_more cursor-point">View All</button>
            </div>
          </Link>
        </div>
      </section>
      <section className="center_container">
        <div className="card_container">
          {packageData &&
            packageData.length > 0 &&
            packageData.map((data, index) => {
              return <PackageCard data={data} key={`packageData${index}`} />;
            })}
        </div>
      </section>
    </section>
  );
}

export default UpComingPackage;
