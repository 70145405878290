import React, { useState, useEffect } from "react";
import AdminDataTable from "../../component/AdminDataTable";
import { GetCustomerInquiry } from "../../Service/admin.api.service";
function InquiryDetails() {
  const [inquiryData, setinquiryData] = useState("");

  const InquiryDatahandler = async () => {
    const response = await GetCustomerInquiry();
    setinquiryData(response.data);
  };

  useEffect(() => {
    InquiryDatahandler();
  }, []);

  const columData = [
    {
      label: "Name",
    },
    {
      label: "MobileNumber",
    },
    {
      label: "email",
    },
    {
      label: "pickup",
    },
    {
      label: "isPopular",
    },
    {
      label: "isUpcoming",
    },
  ];
  return (
    <>
      {inquiryData && (
        <AdminDataTable
          tableTitleData={columData}
          TableData={inquiryData.map((d, index) => {
            return (
              <tr key={`inquiryData${index}`}>
                <td>{d.name}</td>
                <td>{d.mobileNumber}</td>
                <td>{d.email}</td>
              </tr>
            );
          })}
        />
      )}
    </>
  );
}

export default InquiryDetails;
