import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { User } from "../../utils/Data";
import Sidebar from "../Sidebar";
import { GetContactUs } from "../../Service/admin.api.service";
import AdminDataTable from "../../component/AdminDataTable";
import DeleteIcon from "../../assets/image/delete.png";
import EditIcon from "../../assets/image/edit.png";

function AdminContactUs(props) {
  const [contactUsData, setContactUsData] = useState();

  const columData = [
    {
      label: "Name",
    },
    {
      label: "Email Id ",
    },
    {
      label: "Mobile No.",
    },
    {
      label: "Message",
    },
  ];

  const getContactUsData = async (id) => {
    const response = await GetContactUs(id);
    setContactUsData(response.data);
  };

  useEffect(() => {
    getContactUsData(User.userId);
  }, []);

  return (
    <>
      <div className="admin_main_section">
        <Sidebar />
        <div className="admin_start">
          <div className="heading_div">
            <h1> Contact Us </h1>
          </div>
          <div>
            {contactUsData && (
              <AdminDataTable
                tableTitleData={columData}
                TableData={contactUsData.map((d, index) => {
                  return (
                    <tr className="text_center" key={`contactUsData${index}`}>
                      <td>{d.name}</td>
                      <td>{d.email}</td>
                      <td>{d.mobileNo}</td>
                      <td>{d.message}</td>
                    </tr>
                  );
                })}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminContactUs;
