import React from "react";
import "./HeroBanner.css";
function HomeHeroBanner(props) {
  return (
    <>
      <section>
        <div className="main_container">
          {props.PackageDetail ? (
            <div
              className="Package_Detail_top_container"
              style={{
                backgroundImage: `url(${props?.data?.tittleImageUrl} )`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                right: "0",
                width: "100%",
              }}
            ></div>
          ) : (
            <div
              className="Package_Detail_top_container"
              style={{
                backgroundImage: `url(${props.Image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                right: "0",
                width: "100%",
              }}
            ></div>
          )}
          <div className="top_container_about">
            {props.PackageDetail ? (
              " "
            ) : (
              <p className="home_banner_text"> {props.Text} </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeHeroBanner;
