import React, { useState, useEffect } from "react";
import AdminDataTable from "../../component/AdminDataTable";
import { User } from "../../utils/Data";
import Sidebar from "../Sidebar";
import TextField from "@mui/material/TextField";
import { Select } from "@mui/material";
import "./SubCategories.css";
import {
  CreateSubCategory,
  DeleteSubCategory,
  EditSubCategory,
  GetSubCategory,
} from "../../Service/admin.api.service";
import DeleteIcon from "../../assets/image/delete.png";
import EditIcon from "../../assets/image/edit.png";
import { GetCategorey } from "../../Service/api.sevice";
import { errorToast } from "../../utils/helper";

function SubCategories() {
  const [subCategoyData, setSubCategoryData] = useState();
  const [cateGoreyList, setCategoreyList] = useState("");
  const [categoryId, setCategoryId] = useState();
  const [subCategory, setSubCategory] = useState();
  const [subCategoryId, setSubCategoryId] = useState();

  const columData = [
    {
      label: "Name",
    },
    {
      label: "Category Name",
    },
    {
      label: "Edit/Delete",
    },
  ];

  const getSubCategoyData = async (userId) => {
    const response = await GetSubCategory(userId);
    setSubCategoryData(response.data);
  };

  const deleteSubCategory = async (id) => {
    await DeleteSubCategory(id);
    getSubCategoyData(User.userId);
  };

  const getCategory = async () => {
    const cateGoreyData = await GetCategorey();
    setCategoreyList(cateGoreyData.data);
  };

  const addSubcategory = async () => {
    if (categoryId && subCategory.trim()) {
      if (
        subCategoryId === null ||
        subCategoryId === undefined ||
        subCategoryId === ""
      ) {
        const data = {
          categoryId: parseInt(categoryId),
          name: subCategory,
        };
        const createSubcategory = await CreateSubCategory(data);
      } else {
        const data = {
          id: parseInt(subCategoryId),
          categoryId: parseInt(categoryId),
          name: subCategory,
        };
        const updateSubcategory = await EditSubCategory(data);
      }
      setSubCategoryId("");
      setCategoryId("");
      setSubCategory("");
      await getSubCategoyData(User.userId);
    } else {
      errorToast("please fill up data");
    }
  };

  const editSubCategory = async (id, editcategoryId, name) => {
    setSubCategoryId(parseInt(id));
    setCategoryId(parseInt(editcategoryId));
    setSubCategory(name);
  };

  useEffect(async () => {
    await getCategory();
    await getSubCategoyData(User.userId);
  }, []);
  return (
    <>
      <div className="admin_main_section">
        <Sidebar />
        <div className="admin_start">
          <div className="heading_div"></div>
          <div className="SubCate_main">
            <div>
              <input type="hidden" value={subCategoryId} />
              <label> categorey</label>
              <select onChange={(e) => setCategoryId(e.target.value)}>
                <option>Select categorey</option>
                {cateGoreyList &&
                  cateGoreyList.length > 0 &&
                  cateGoreyList.map((data, index) => {
                    if (data.id == categoryId) {
                      return (
                        <option
                          key={`cateGoreyList ${index}`}
                          value={data.id}
                          selected
                        >
                          {data.name}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          key={`cateGoreyList21 ${index}`}
                          value={data.id}
                        >
                          {data.name}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>

            <div>
              <TextField
                type="text"
                fullWidth
                size="small"
                value={subCategory}
                label="Enter sub-Category"
                onChange={(e) => setSubCategory(e.target.value)}
              />
            </div>
            <div>
              <button
                className="submit_Btn"
                // disabled={!buttonActive}
                onClick={() => addSubcategory()}
              >
                Submit
              </button>
            </div>
          </div>

          <div className="Data_table_main">
            {subCategoyData && (
              <AdminDataTable
                tableTitleData={columData}
                TableData={subCategoyData.map((d, index) => {
                  return (
                    <tr className="text_center" key={`subCategoyData${index}`}>
                      <td>{d.subCategoryName}</td>
                      <td>{d.categoryName}</td>
                      <td>
                        <div className="table_Icon_admin">
                          <img
                            src={EditIcon}
                            alt=""
                            width="30px"
                            height="30px"
                            className="cursor-point"
                            onClick={() =>
                              editSubCategory(
                                d.id,
                                d.categoryId,
                                d.subCategoryName
                              )
                            }
                          />
                          <img
                            src={DeleteIcon}
                            alt=""
                            className="cursor-point"
                            width="50px"
                            height="50px"
                            onClick={() => deleteSubCategory(d.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SubCategories;
