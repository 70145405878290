import React, { useEffect, useState } from "react";
import {
  AddPackageData,
  CreatePackageData,
  DeletePackageImageById,
  EditPackageByEdit,
  UpdatePackage,
  GetSubCatBycategorey,
} from "../../Service/admin.api.service";
import { User } from "../../utils/Data";

import DeleteIcon from "../../assets/image/delete.png";
import $ from "jquery";
import moment from "moment";
import DatePicker from "react-datepicker";
import Sidebar from "../Sidebar";
import "react-datepicker/dist/react-datepicker.css";
import Delete from "../../assets/image/delete-svgrepo-com.svg";
import ADDImage from "../../assets/image/add-image .png";
import { GetCategorey } from "../../Service/api.sevice";
import Itinerary from "./Itinerary";
import IncExc from "./IncExc";
import { errorToast } from "../../utils/helper";

function CreatePackage() {
  const [price, setPrice] = useState();
  const [description, setDescription] = useState("");
  const [subCategoreyId, setSubCategoreyId] = useState([]);
  const [categorey, setCategorey] = useState("");
  const [stay, setStay] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [pickUp, setPickup] = useState("");
  const [videoLink, setVideolink] = useState("");
  const [titleImage, setTitleImage] = useState("");
  const [title, setTitle] = useState("");
  const [displayImage, setDisplayImage] = useState("");
  const [id, setId] = useState("");
  const [ImageGallery, SetImageGallery] = useState("");
  const [cateGoreyList, setCategoreyList] = useState("");
  const [subCategoreyList, setSubCategoreyList] = useState("");
  const [selectedSubCatList, setSelectedSubCatList] = useState([]);
  const [itineraryData, setItineraryData] = useState("");
  const [incExcData, setIncExcData] = useState("");

  const DataHandler = async () => {
    console.log(
      "title",
      title,
      titleImage,
      videoLink,
      pickUp,
      startDate,
      endDate,
      stay,
      categorey,
      subCategoreyId,
      description,
      price
    );
    if (
      title &&
      titleImage &&
      videoLink &&
      pickUp &&
      startDate &&
      endDate &&
      stay &&
      categorey &&
      subCategoreyId.length > 0 &&
      description &&
      price
    ) {
      const PackageData = new FormData();
      PackageData.append("userId", User.userId);
      PackageData.append("title", title);
      PackageData.append("titleImageUrl", titleImage);
      PackageData.append("videoLink", videoLink);
      PackageData.append("pickup", pickUp);
      PackageData.append("startDate", formatDate(startDate));
      PackageData.append("endDate", formatDate(endDate));
      PackageData.append("stay", stay);
      PackageData.append("categoryId", categorey);
      PackageData.append("subcategoryIds", subCategoreyId.join(","));
      PackageData.append("id", id ? id : "");
      PackageData.append("description", description);
      PackageData.append("price", price);

      if (id) {
        const Response = await UpdatePackage(PackageData);
      } else {
        const Response = await CreatePackageData(PackageData);
        setId(Response.data.data.id);
        if (Response?.data.data.id) {
          setData(Response.data.data.id);
        }
      }
    } else {
      errorToast("Please Fill up data");
    }
  };

  const DeleteImageGallery = async (id) => {
    const response = await DeletePackageImageById(id);
    if (response.code === 200) {
      EditHandler(response.data.packageId);
    }
  };

  // const

  const IncExcinput = [
    {
      packageId: id,
      name: "",
      type: "",
    },
  ];

  useEffect(async () => {
    const Id = window.location.pathname.split(`/`)[2];
    if (Id) {
      console.log("Edit", Id);
      await setId(Id);
      await EditHandler(Id);
    }
  }, []);

  const data = [];

  const setData = async (packageId) => {
    for (let i = 1; i <= DayCounter + 1; i++) {
      await data.push({
        packageId: Number(packageId),
        days: i,
        itineraryName: "",
      });
    }
    await setItineraryData(data);
  };

  const ImageHandler = () => {
    $("#chooce_picture").click();
  };

  useEffect(async () => {
    const cateGoreyData = await GetCategorey();
    setCategoreyList(cateGoreyData.data);
  }, []);

  const ImageUpload = async (e) => {
    const ImageData = new FormData();
    ImageData.append("packageId", id);
    ImageData.append("imageUrl", e.target.files[0]);

    const response = await AddPackageData(ImageData);
    if (response) {
      EditHandler(response.data.data.packageId);
    }
  };

  const DayHandler = (startDate, endDate) => {
    return moment(endDate).diff(moment(startDate), "days");
  };
  let DayCounter = DayHandler(startDate, endDate);

  const EditHandler = async (id) => {
    const response = await EditPackageByEdit(id);
    setPrice(response.data.price);
    setPickup(response.data.pickup);
    setTitle(response.data.tittle);
    setVideolink(response.data.videolink);
    setStay(response.data.stay);
    setTitleImage(response.data.tittleImageUrl);
    setDisplayImage(response.data.tittleImageUrl);
    setDescription(response.data.description);
    SetImageGallery(response.data.PackageImageGallery);
    console.log(
      "response.data.PackageItinerary.length",
      response.data.PackageItinerary.length
    );
    setStartDate(new Date(response.data.startDate).getTime());
    setEndDate(new Date(response.data.endDate).getTime());
    const counter = DayHandler(
      new Date(response.data.startDate).getTime(),
      new Date(response.data.endDate).getTime()
    );
    if (response.data.PackageItinerary.length == 0) {
      const setData = async (packageId) => {
        console.log("counter", counter);
        for (let i = 1; i <= counter + 1; i++) {
          await data.push({
            packageId: Number(packageId),
            days: i,
            itineraryName: "",
          });
        }
        await setItineraryData(data);
      };
      await setData(id);
    } else {
      await setItineraryData(response.data.PackageItinerary);
    }

    setIncExcData(
      response.data.PackageInclusionExclusion &&
        response.data.PackageInclusionExclusion
    );
    await setCategorey(response.data.categoryId);
    const sub = await response.data.PackageSubCategorey.map((data) => {
      return {
        name: data.SubCategory.name,
        Id: data.SubCategory.id,
      };
    });
    await setSelectedSubCatList(sub);
    await setSubCategoreyId(
      response.data.PackageSubCategorey.map((data) => data.SubCategory.id)
    );
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterPassedTime1 = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return startDate < selectedDate.getTime();
  };

  const GetSubcateGoreyData = async (e) => {
    setCategorey(e.target.value);
    const Response = await GetSubCatBycategorey(e.target.value);
    setSubCategoreyList(Response.data.SubCategory);
  };

  const GetSubcategoreyData = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const selectedSubId = el.getAttribute("id");
    subCategoreyId.push(parseInt(selectedSubId));
    setSubCategoreyId([...new Set(subCategoreyId)]);
    const subcateData = {
      name: e.target.value,
      Id: selectedSubId,
    };
    const updatedSubCatList = [...selectedSubCatList, subcateData];
    setSelectedSubCatList([...new Set(updatedSubCatList)]);
  };

  const Deletesubcat = (name) => {
    const list = selectedSubCatList.filter((data) => name.name !== data.name);
    setSelectedSubCatList(list);
    const data78 = list.map((data) => data.Id);
    subCategoreyId.push(parseInt(data78));
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  return (
    <>
      <div className="admin_main_section">
        <Sidebar />
        <div className="admin_start">
          <div className="heading_div">
            <h4> Create Package </h4>
          </div>
          <div>
            <label> title image url </label>
            <br />
            {displayImage && (
              <img src={displayImage} alt="" height="200px" width="200px" />
            )}
            <input
              type="file"
              placeholder="upload picture"
              onChange={(e) => [
                setTitleImage(e.target.files[0]),
                setDisplayImage(URL.createObjectURL(e.target.files[0])),
              ]}
            />
          </div>
          <div className="Create_Package_main">
            <div>
              <div>
                <label> title </label>
                <input
                  placeholder="title"
                  className="create_package_field"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div>
                <label> stay </label>
                <input
                  placeholder="stay"
                  type="text"
                  className="create_package_field"
                  value={stay}
                  onChange={(e) => setStay(e.target.value)}
                />
              </div>
            </div>

            <div>
              <div>
                <label> video link </label>
                <input
                  placeholder="video link"
                  type="text"
                  className="create_package_field"
                  value={videoLink}
                  onChange={(e) => setVideolink(e.target.value)}
                />
              </div>
              <div>
                <label> pick up </label>
                <input
                  placeholder="pick up"
                  type="text"
                  className="create_package_field"
                  value={pickUp}
                  onChange={(e) => setPickup(e.target.value)}
                />
              </div>
            </div>

            <div>
              <div>
                <label> start date </label>
                <DatePicker
                  selected={startDate}
                  className="create_package_field"
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MMMM dd, yyyy"
                  filterTime={filterPassedTime}
                  minDate={new Date()}
                />
              </div>
              <label> categorey </label>
              <select onChange={GetSubcateGoreyData}>
                <option> Select categorey</option>
                {cateGoreyList &&
                  cateGoreyList.length > 0 &&
                  cateGoreyList.map((data, index) => {
                    if (data.id == categorey) {
                      return (
                        <option
                          className="create_package_field"
                          key={`cateGoreyList12${index}`}
                          value={data.id}
                          selected
                        >
                          {data.name}
                        </option>
                      );
                    } else {
                      return (
                        <option key={`cateGoreyList21${index}`} value={data.id}>
                          {data.name}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>

            <div>
              <div>
                <label> end date</label>
                <DatePicker
                  onChange={(date) => setEndDate(date)}
                  selected={endDate}
                  className="create_package_field"
                  dateFormat="MMMM dd, yyyy"
                  filterTime={filterPassedTime1}
                  minDate={startDate != null ? startDate : new Date()}
                />
              </div>
              <div>
                <label> subcategorey </label>
                {selectedSubCatList &&
                  selectedSubCatList.length > 0 &&
                  selectedSubCatList.map((data, index) => {
                    return (
                      <div key={`selectedSubCatList${index}`}>
                        <div>
                          {data.name}
                          <img
                            src={Delete}
                            height="15"
                            width="15"
                            alt=""
                            onClick={() => Deletesubcat(data)}
                          />
                        </div>
                      </div>
                    );
                  })}
                <select onChange={GetSubcategoreyData}>
                  <option> select Subcategorey </option>
                  {subCategoreyList &&
                    subCategoreyList.length > 0 &&
                    subCategoreyList.map((data, index) => {
                      return (
                        <option
                          key={`GetSubcategoreyData${index}`}
                          value={data.name}
                          id={data.id}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div>
              <div>
                <label>description </label>
                <input
                  type="text"
                  className="create_package_field"
                  value={description}
                  placeholder="description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            <div>
              <div>
                <label> Price </label>
                <input
                  type="number"
                  value={price}
                  className="create_package_field"
                  placeholder="price"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                className="create_package submit_Btn"
                onClick={() => DataHandler()}
              >
                Submit
              </button>
            </div>
          </div>
          {id && (
            <div className="multiple_images">
              <>
                {ImageGallery &&
                  ImageGallery.length > 0 &&
                  ImageGallery.map((data, index) => {
                    return (
                      <div key={`ImageGallery${index}`}>
                        <img
                          src={data.imageUrl}
                          alt="Package_image"
                          height="200px"
                          width="200px"
                        />
                        <img
                          src={DeleteIcon}
                          alt=""
                          width="30px"
                          height="30px"
                          style={{ cursor: "pointer" }}
                          onClick={() => DeleteImageGallery(data.id)}
                        />
                      </div>
                    );
                  })}
                <div>
                  <img
                    src={ADDImage}
                    alt="image"
                    style={{ cursor: "pointer" }}
                    onClick={() => ImageHandler()}
                  />
                  <input
                    type="file"
                    className="Image_Input"
                    id="chooce_picture"
                    style={{ display: "none" }}
                    onChange={ImageUpload}
                  />
                </div>
              </>
            </div>
          )}
          {id && (
            <div>
              <Itinerary
                DaysCount={DayCounter + 1}
                itineraryData={itineraryData}
                flag={incExcData ? true : false}
                PackageId={id}
              />
            </div>
          )}
          {id && (
            <div>
              <IncExc
                PackageId={id}
                IncExcinput={incExcData}
                flag={incExcData ? true : false}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CreatePackage;
