import React, { useEffect, useState } from "react";
import Footer from "../../comman/Footer";
import Header from "../../comman/Header";
import "./PackageDetail.css";
import HomeHeroBanner from "../../component/HomeHeroBanner";
import PackageDetailsInfo from "../../component/PackageDetailInfo";
import PackageImageVideo from "../../component/PackageImageVideo";
import TittleButtonDescription from "../../component/TittleButtonDescription";
import Itinerary from "../../component/Itinerary";
import PackageReview from "../../component/PackageReview";
import { useParams } from "react-router-dom";
import { GetAllPackageDetails } from "../../Service/admin.api.service";
import ReviewPopUp from "../../component/Review_pop_up";

function PackageDetail(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const ModalCloseHandler = () => {
    setOpen(false);
  };

  const { id } = useParams();
  const [packageDetails, setPackageDetails] = useState([]);

  useEffect(() => {
    PackagedetailHandler(id);
  }, [id]);

  const PackagedetailHandler = async (id) => {
    const response = await GetAllPackageDetails(id);
    setPackageDetails(response.data);
  };

  return (
    <>
      <Header />
      <HomeHeroBanner PackageDetail="true" data={packageDetails} />
      <PackageImageVideo data={packageDetails} />
      <TittleButtonDescription data={packageDetails} Id={id} />

      <section className="center_container">
        <div className="table_container">
          <PackageDetailsInfo data={packageDetails} />
          <div className="table_container_column">
            <h2>Itinerary</h2>
            {packageDetails?.PackageItinerary?.map((res, index) => {
              return (
                <Itinerary
                  que={res.days}
                  ans={res.itineraryName}
                  key={`packageDetails${index}`}
                />
              );
            })}
          </div>
        </div>
      </section>
      <section className="center_container">
        {packageDetails.CustomerReview?.map((res, index) => {
          return (
            <PackageReview
              key={`PackageItinerary${index}`}
              Image={res.imageUrl}
              Message={res.message}
              Name={res.name}
            />
          );
        })}

        <div className="Give_Review" onClick={() => handleOpen()}>
          Give Some Review
        </div>
      </section>
      <ReviewPopUp
        isOpen={open}
        handleClose={ModalCloseHandler}
        PackageId={id}
      />

      <Footer />
    </>
  );
}

export default PackageDetail;
