import React, { useEffect, useState } from "react";
import { GetCategorey, GetPackageById } from "../../Service/api.sevice";
import PackageCard from "../PackageCard";
import "./SpecialPackage.css";

function SpecialPackage() {
  const [categoreyData, setCategoreyData] = useState();
  const [packageDetail, setPackageDetail] = useState("");

  // call function used for the get categorey list
  const getCategorey = async () => {
    const data = await GetCategorey();
    setCategoreyData(data);

    // by default we pass id for the package detail
    await getPackageById(data.data[0].id);
  };

  useEffect(() => {
    // call function for the get categorey
    getCategorey();
  }, []);

  // get id to get package
  const getPackageById = async (id) => {
    const getPackageById = await GetPackageById(id, 3);
    if (getPackageById.code === 200) {
      setPackageDetail(getPackageById.data);
    }
  };

  return (
    <>
      <section className="Our_Special_Packages center_container">
        <section className="center_container">
          <div className="heading_container">
            <div className="heading_column special_left_column">
              <div>
                <p className="heading_text_1">What we serve</p>
                <p className="heading_text_2">Our Special Packages</p>
              </div>
            </div>
            <div className="heading_column special_righ_column ">
              {categoreyData &&
                categoreyData.data.map((data, index) => {
                  return (
                    <a
                      key={`heading_column categoreyData${index}`}
                      className="cursor-point"
                      onClick={() => getPackageById(data?.id)}
                    >
                      <p className="domestic_p_color">{data?.name}</p>
                    </a>
                  );
                })}
            </div>
          </div>
        </section>
        <section className="center_container">
          <div className="card_container">
            {packageDetail &&
              packageDetail.length > 0 &&
              packageDetail.map((data, index) => {
                return (
                  <PackageCard data={data} key={`center_container${index}`} />
                );
              })}
          </div>
          <div className="view_all_btn">
            <a href="/">
              <button className="read_more">View All</button>
            </a>
          </div>
        </section>
      </section>
    </>
  );
}

export default SpecialPackage;
