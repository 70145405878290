import React, { useEffect, useState } from "react";
import AdminDataTable from "../../component/AdminDataTable";
import { styled } from "@mui/material/styles";
import { GetAllPackage } from "../../Service/api.sevice";
import {
  setIsActive,
  setPackagePopular,
  setUpcomingpackage,
} from "../../Service/admin.api.service.js";
import EditIcon from "../../assets/image/edit.png";
import { useNavigate } from "react-router-dom";
import { FormControlLabel, Switch } from "@mui/material";

function PackageDetail() {
  const navigate = useNavigate();
  const [packageDetailData, setPackageDetailData] = useState("");

  const columData = [
    {
      label: "tittle",
    },
    {
      label: "price",
    },
    {
      label: "pickup",
    },
    {
      label: "StartDate - EndDate",
    },
    {
      label: "isActive",
    },
    {
      label: "isPopular",
    },
    {
      label: "isUpcoming",
    },
    {
      label: "Action",
    },
  ];

  useEffect(async () => {
    const Response = await GetAllPackage("all", 0);
    setPackageDetailData(Response.data);
  }, []);

  const setIsActivePackage = async (id, status) => {
    await setIsActive(id, status);
    const Response = await GetAllPackage("all", 0);
    await setPackageDetailData(Response.data);
  };

  const setPopularPackage = async (id, status) => {
    await setPackagePopular(id, status);
    const Response = await GetAllPackage("all", 0);
    await setPackageDetailData(Response.data);
  };

  const SetUpcomingPackage = async (id, status) => {
    await setUpcomingpackage(id, status);
    const Response = await GetAllPackage("all", 0);
    await setPackageDetailData(Response.data);
  };

  const EdiPackageHandler = async (id) => {
    navigate(`/createpackage/${id}`);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      {packageDetailData && (
        <AdminDataTable
          tableTitleData={columData}
          TableData={packageDetailData.map((d, index) => {
            return (
              <tr
                style={{ textAlign: "center" }}
                key={`packageDetailData${index}`}
              >
                <td>{d.tittle}</td>
                <td>{d.price}</td>
                <td>{d.pickup}</td>
                <td>{`${new Date(d.startDate).toDateString()} - ${new Date(
                  d.endDate
                ).toDateString()}`}</td>
                <td
                  style={{ textAlign: "center" }}
                  onClick={() => setIsActivePackage(d.id, !d.isActive)}
                >
                  <FormControlLabel
                    control={<IOSSwitch checked={d.isActive} sx={{ m: 1 }} />}
                  />
                </td>
                <td
                  style={{ textAlign: "center" }}
                  onClick={() => setPopularPackage(d.id, !d.isPopular)}
                >
                  <FormControlLabel
                    control={<IOSSwitch checked={d.isPopular} sx={{ m: 1 }} />}
                  />
                </td>
                <td
                  style={{ textAlign: "center" }}
                  onClick={() => SetUpcomingPackage(d.id, !d.isUpcoming)}
                >
                  <FormControlLabel
                    control={<IOSSwitch checked={d.isUpcoming} sx={{ m: 1 }} />}
                  />
                </td>
                <td>
                  <div className="table_Icon_admin">
                    <img
                      src={EditIcon}
                      alt=""
                      width="30px"
                      height="30px"
                      style={{ cursor: "pointer" }}
                      onClick={() => EdiPackageHandler(d.id)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        />
      )}
    </>
  );
}

export default PackageDetail;
