import React from "react";
import Footer from "../../comman/Footer";
import Header from "../../comman/Header";
import HomeHeroBanner from "../../component/HomeHeroBanner";
import home_background from "../../assets/image/FinalAboutUS.png";
import Image1 from "../../assets/image/img1.jpg";
import Image2 from "../../assets/image/img2.jpg";
import Image3 from "../../assets/image/img3.jpg";
import "./AboutUs.css";
function AboutUs() {
  return (
    <>
      <Header />
      <HomeHeroBanner Image={home_background} Text={``} />
      <section className="center_container">
        <div className="Photo_Section">
          <div className="column">
            <img className="column_img_1" src={Image1} alt="" />
            {/* <div className="Photo_text_container">
              <div className="photo_quote">
                <i className="fa fa-quote-left" aria-hidden="true"></i>
              </div>
              <p className="Photo_Section_header">
                Lago di Braies, Braies, Italy
              </p>
              <p className="Photo_Section_text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it specimen book
              </p>
            </div> */}
          </div>
          <div className="column">
            <img className="column_img_2" src={Image2} alt="" />
          </div>
          <div className="column">
            <img className="column_img_2" src={Image3} alt="" />
          </div>
        </div>
      </section>
      <section>
        <div className="content_container center_container">
          <p className="first">
            Shree Umiya Toursim is a
            Gujarat based Travel and Tourism Company Having its office in 91, Hirabag Society
            Part 1, Near C. P. Nagar Cross Road, Ghatlodiya, Ahmedabad, Gujarat – India
          </p>

          <p>
          We are a team of professionals driven by a desire to change the whole landscape of Travel and
          Tourism in the region.
          We believe in innovation, not imitation and we ensure that all the ingredients of our tours
          are right-not just the price. Having the huge amount of enthusiasm, energy and commitment
          for Travel, we focus on stimulating your Travel experience. We offer unique range of
          Tourism products from General Tourism, Adventure Tourism, Film Tourism, Corporate
          Travel, and Incentive Tourism to Event Management.We are specialized in Kashmir Virgin
          Destinations so that our valuable guests will explore unexplored and untouched beauties of
          Kashmir
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AboutUs;
