import React from "react";
import Footer from "../../comman/Footer";
import Header from "../../comman/Header";
import HomeHeroBanner from "../../component/HomeHeroBanner";
import Information from "../../component/Information";
import PackageInfo from "../../component/PackageInfo";
import PopularPackage from "../../component/PopularPackage";
import SpecialPackage from "../../component/SpecialPackage";
import Testimonials from "../../component/Testimonials";
import UpComingPackage from "../../component/UpComingPackage";
import Whatsupp from "../../assets/image/whatsapp.png";
import Homebackground from "../../assets/image/home_backgroundjpg.jpg";

function Home() {
  return (
    <>
      <Header />
      <a
        class="whats-app"
        href="https://wa.me/919974408008/?text=hi"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Whatsupp} alt="Whatsupp" className="whats-app" />
      </a>
      <HomeHeroBanner
        HomePage={true}
        Image={Homebackground}
        // Image={
        //   "https://lh3.googleusercontent.com/l85mtbFmyiUbd5HlwoI873Ok1DNyd-voe_vT7EztVFNh8CVTp8zahIHvAnJmzEBtnIc=w2400"
        // }
      />
      <Information />
      {/* <PackageInfo /> */}
      <PopularPackage />
      <UpComingPackage />
      <SpecialPackage />
      <Testimonials />
      <Footer />
    </>
  );
}

export default Home;
