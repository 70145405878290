import React from "react";
import "./TestimonialCard.css";
import inverted_Commas from "../../../assets/image/inverted_Commas.svg";
function TestimonialsCard({ data, color }) {
  return (
    <>
      <div className="testimonial_card">
        {data.imageUrl && (
          <div className="testimonial_img">
            <img src={data.imageUrl} alt="UserImage" />
            <div className="quote_box" style={{ background: color }}>
              <img src={inverted_Commas} alt="" className="Image_seter" />
            </div>
          </div>
        )}
        <div className="testimonial_text">
          <p>{data.message}</p>
          <div className="dot" style={{ background: color }}></div>
          <div className="testimonial_name">
            <p className="name">{data.name}</p>
            <p className="review">Happy Customer</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestimonialsCard;
