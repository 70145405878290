import React, { useEffect, useState } from "react";
import AdminDataTable from "../../component/AdminDataTable";
import { GetAllCarData } from "../../Service/api.sevice";
import DeleteIcon from "../../assets/image/delete.png";
import EditIcon from "../../assets/image/edit.png";
import { DeleteCarById } from "../../Service/admin.api.service";
import { useNavigate } from "react-router-dom";
function CarRentalData() {
  const navigate = useNavigate();
  const [carData, setCarData] = useState("");

  const CarRentalData = async () => {
    const Data = await GetAllCarData();
    setCarData(Data.data);
  };

  const DeleteHandler = async (id) => {
    const response = await DeleteCarById(id);
  };

  const EdiHandler = async (id) => {
    navigate(`/admin/createcar/${id}`);
  };

  useEffect(() => {
    CarRentalData();
  }, []);

  const columData = [
    {
      label: "Image",
    },
    {
      label: "Name",
    },
    {
      label: "price",
    },
    {
      label: "action",
    },
  ];

  return (
    <>
      {carData && (
        <AdminDataTable
          tableTitleData={columData}
          TableData={carData.map((data, index) => {
            return (
              <tr className="text_center" key={`carData${index}`}>
                <td>
                  <img
                    src={data.imageUrl}
                    alt="car_Image"
                    width="80px"
                    height="80px"
                  />
                </td>
                <td>{data.name}</td>
                <td>{data.price}</td>
                <td>
                  <div className="table_Icon_admin">
                    <img
                      src={EditIcon}
                      alt=""
                      width="30px"
                      height="30px"
                      style={{ cursor: "pointer" }}
                      onClick={() => EdiHandler(data.id)}
                    />
                    <img
                      src={DeleteIcon}
                      alt=""
                      width="30px"
                      height="30px"
                      style={{ cursor: "pointer" }}
                      onClick={() => DeleteHandler(data.id)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        />
      )}
    </>
  );
}

export default CarRentalData;
