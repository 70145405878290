import React, { useEffect, useState } from "react";
import Footer from "../../comman/Footer";
import Header from "../../comman/Header";
import domestic from "../../assets/image/domestic_img.jpg";
import domestic12 from "../../assets/image/FinalDomestic.jpg";
import { PackageId } from "../../utils/helper";
import PackageCard from "../../component/PackageCard";
import HomeHeroBanner from "../../component/HomeHeroBanner";
import { GetPackageById } from "../../Service/api.sevice";
import "./Domestic.css";

function Domestic() {
  const [packageData, setPackageData] = useState(null);

  useEffect(() => {
    GetDomesticData();
  }, []);

  const GetDomesticData = async () => {
    const getId = PackageId.DomesticPackage;
    const responseData = await GetPackageById(getId, 0);
    setPackageData(responseData?.data);
  };

  return (
    <>
      <Header />
      <HomeHeroBanner Image={domestic12} Text={``} />
      <section className="center_container">
        <div className="card_container">
          {packageData &&
            packageData.length > 0 &&
            packageData.map((data, index) => {
              return <PackageCard key={`HomeHeroBanner${index}`} data={data} />;
            })}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Domestic;
