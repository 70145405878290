import React from "react";
import { Link } from "react-router-dom";
import { scrollTop } from "../../utils/helper";
import "./TittleButtonDescription.css";
function TittleButtonDescription({ data, Id }) {
  return (
    <>
      <section className="center_container">
        <div className="Package_Info">
          <div className="heading_column heading_left_column">
            <div>
              <p className="heading_text_2"> {data.tittle}</p>
            </div>
          </div>

          <div className="heading_column heading_righ_column">
            <Link
              to="/inquiry"
              onClick={scrollTop}
              state={{
                hotelId: null,
                packageId: Id,
                carId: null,
              }}
            >
              <button className="read_more cursor-point">inquiry</button>
            </Link>
          </div>
        </div>
        <div className="box_text">{data.description}</div>
      </section>
    </>
  );
}

export default TittleButtonDescription;
