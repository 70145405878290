import React, { useEffect, useState } from "react";
import "./Package.css";
import { AddItineary } from "../../Service/admin.api.service";
import { errorToast } from "../../utils/helper";

function Itinerary(props) {
  const [itineraryData, setItineraryData] = useState([]);

  const ItineraryDataHandler = (days, value) => {
    props.itineraryData.find((d) => {
      if (d.days === days) {
        d.itineraryName = value;
      }
    });
  };

  const submitHandler = async () => {
    let isEmptyInput = false;
    for (let i = 0; i < props.itineraryData.length; i++) {
      const element = props.itineraryData[i];
      if (element.itineraryName.trim() == "") {
        isEmptyInput = true;
        break;
      }
    }

    if (isEmptyInput) {
      errorToast("please fill up data");
    } else {
      const response = await AddItineary({ itinerary: props.itineraryData });
    }
  };

  return (
    <>
      <div className="itinerary_main">
        <div>
          <h2> Days </h2>
        </div>
        <div>
          <h2> Item</h2>
        </div>
      </div>

      {props.itineraryData.length > 0 &&
        props.itineraryData.map((data, index) => {
          return (
            <div key={`itineraray_data${index}`} className="itineraray_data">
              <div> {data.days}</div>
              <div>
                <input
                  type="text"
                  className="create_package_field"
                  placeholder="itineraryitem"
                  defaultValue={data.itineraryName && data.itineraryName}
                  required
                  onChange={(e) =>
                    ItineraryDataHandler(data.days, e.target.value)
                  }
                />
              </div>
            </div>
          );
        })}
      <button className="submit_Btn" onClick={submitHandler}>
        SUBMIT
      </button>
    </>
  );
}

export default Itinerary;
