import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ENDPOINTURL = "https://api.shreeumiyatourism.com";
// export const ENDPOINTURL = "http://localhost:3001";
// http://localhost:3100
// http://103.93.16.46:3619

export const PackageId = {
  DomesticPackage: 1,
  InternationalPackage: 2,
  GroupTour: 3,
};

export function successToast(message) {
  toast.success(message, {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function errorToast(message) {
  toast.error(message, {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
}

export const scrollTop = () => {
  window.scrollTo(0, 0);
};
