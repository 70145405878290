import React, { useEffect, useState } from "react";
import AdminDataTable from "../../component/AdminDataTable";
import { DeleteReview, GetReviewAdmin } from "../../Service/admin.api.service";
import { User } from "../../utils/Data";
import Sidebar from "../Sidebar";
import DeleteIcon from "../../assets/image/delete.png";

function AdminReviews(props) {
  const [reviewData, setReviewData] = useState();

  const columData = [
    {
      label: "Image",
    },
    {
      label: "Name",
    },
    {
      label: "Star",
    },
    {
      label: "Message",
    },
    {
      label: "Package Title",
    },
    {
      label: "Delete",
    },
  ];

  const getReviewData = async (id, count) => {
    const response = await GetReviewAdmin(id, count);
    setReviewData(response.data);
  };

  const deleteReview = async (reviewId) => {
    await DeleteReview(reviewId);
    getReviewData(User.userId, 0);
  };

  useEffect(() => {
    getReviewData(User.userId, 0);
  }, []);
  return (
    <>
      <div className="admin_main_section">
        <Sidebar />
        <div className="admin_start">
          <div className="heading_div">
            <h1> Reviews </h1>
          </div>
          <div>
            {reviewData && (
              <AdminDataTable
                tableTitleData={columData}
                TableData={reviewData.map((d, index) => {
                  return (
                    <tr className="text_center" key={`reviewData${index}`}>
                      <td>
                        <img
                          src={d.imageUrl}
                          alt="Profile Picture"
                          srcSet=""
                          height="50px"
                          width="50px"
                        />
                      </td>
                      <td>{d.name}</td>
                      <td>{d.reviewNumber}</td>
                      <td>{d.message}</td>
                      <td>{d.package.tittle}</td>
                      <td>
                        <div className="table_Icon_admin">
                          <img
                            src={DeleteIcon}
                            alt=""
                            width="50px"
                            height="50px"
                            onClick={() => deleteReview(d.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminReviews;
