import React, { useEffect, useState } from "react";
import domestic from "../../assets/image/domestic_img.jpg";
import Footer from "../../comman/Footer";
import Header from "../../comman/Header";
import HomeHeroBanner from "../../component/HomeHeroBanner";
import PackageCard from "../../component/PackageCard";
import { GetAllPackage } from "../../Service/api.sevice";
function Popular() {
  const [popularData, setPopularData] = useState(null);
  const getPackageData = async () => {
    const getAllPackage = await GetAllPackage("upcoming", 0);
    setPopularData(getAllPackage.data);
  };

  useEffect(() => {
    getPackageData();
  }, []);
  return (
    <>
      <Header />
      <HomeHeroBanner Image={domestic} Text={`Popular Package`} />
      <section className="center_container">
        <div className="card_container">
          {popularData &&
            popularData.length > 0 &&
            popularData.map((data, index) => {
              return <PackageCard key={`packageData${index}`} data={data} />;
            })}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Popular;
